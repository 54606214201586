<template>
  <div>
    <!-- <Register/> -->
    <div v-if="megaMenuEnable" class="bg-hidden" @click="clickOutside()"></div>

    <div
      ref="navbar"
      class="navbar"
      :class="{
        'navbar--fixed': megaMenuEnable || isSticky,
        'navbar--static': isStatic,
        'navbar--dark': isDarkColor,
        'navbar--disabled-transition': !showTransition,
      }"
    >
      <div class="navbar__wrap container">
        <Search
          v-if="isSearchEnabled"
          ref="search"
          :search-results="searchResults"
          :search-value="route.query.q"
          @disable:search="disableSearch"
          @search="search"
          @submit:search="submitSearch"
          @show:suggestions="showSuggestions"
          @open:course="openCourse"
        />
        <template v-else>
          <div class="navbar__collapse">
            <div class="navbar__controls">
              <router-link
                class="navbar__brand"
                :to="{ name: ROUTE.Home }"
                :title="$t('common.home')"
              >
                <img src="../assets/menu/hl-logo.svg" />
              </router-link>
              <template v-if="!isShoppingEnabled">
                <router-link
                  class="navbar__brand"
                  :to="{ name: ROUTE.Home }"
                  :title="$t('common.home')"
                >
                  <img src="../assets/menu/lsmu-logo.svg" />
                </router-link>
              </template>
            </div>
          </div>

          <div class="navbar__collapse">
            <div v-if="isShoppingEnabled" class="navbar__navbar-collapse">
              <div class="navbar__megamenu-toggler">
                <a
                  class="navbar__link dropdown-toggle"
                  href="#"
                  @click.prevent="toggleMegaMenu()"
                >
                  <NavigationToggle />
                </a>
              </div>
            </div>
            <div class="navbar__navbar-collapse">
              <ul class="navbar__nav">
                <li class="navbar__item">
                  <router-link
                    active-class="navbar__link--active"
                    class="navbar__link"
                    :to="{ name: 'about' }"
                  >
                    {{ $t("navigation.about") }}
                  </router-link>
                </li>

                <li class="navbar__item">
                  <router-link
                    active-class="navbar__link--active"
                    class="navbar__link"
                    :to="{ name: 'howitworks' }"
                  >
                    {{ $t("navigation.how-it-works") }}
                  </router-link>
                </li>
                <li v-if="isShoppingEnabled" class="navbar__item">
                  <router-link
                    active-class="navbar__link--active"
                    class="navbar__link"
                    :to="{ name: 'page', params: { id: aboutPage } }"
                  >
                    {{ $t("footer.hybridlab.about") }}
                  </router-link>
                </li>
                <li v-if="isShoppingEnabled" class="navbar__item">
                  <router-link
                    active-class="navbar__link--active"
                    :to="{ name: 'contacts' }"
                    class="navbar__link"
                  >
                    {{ $t("navigation.help") }}
                  </router-link>
                </li>
                <DropdownItem
                  v-if="isShoppingEnabled"
                  :items="[
                    {
                      to: { name: 'projects.in-progress' },
                      label: $t('navigation.projects.in_progress'),
                    },
                    {
                      to: { name: 'projects.done' },
                      label: $t('navigation.projects.done'),
                    },
                  ]"
                >
                  {{ $t("navigation.projects.dropdown") }}
                </DropdownItem>
                <template v-if="!isShoppingEnabled">
                  <li v-if="auth2Store.isLoggedIn" class="navbar__item">
                    <router-link
                      active-class="navbar__link--active"
                      :to="{ name: ROUTE.Dashboard.Courses }"
                      class="navbar__link"
                    >
                      {{ $t("navigation.courses") }}
                    </router-link>
                  </li>
                </template>
                <template v-else>
                  <DropdownItem
                    v-if="auth2Store.isLoggedIn"
                    :items="[
                      {
                        to: { name: ROUTE.Dashboard.Courses },
                        label: $t('common.my_courses'),
                      },
                      {
                        to: { name: 'discover' },
                        label: $t('common.all_courses'),
                      },
                    ]"
                  >
                    {{ $t("navigation.courses") }}
                  </DropdownItem>
                  <li v-else class="navbar__item">
                    <router-link
                      active-class="navbar__link--active"
                      class="navbar__link navbar__link--icon"
                      :to="{ name: ROUTE.Discover }"
                    >
                      {{ $t("navigation.courses") }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="navbar__navbar-collapse">
            <ul class="navbar__nav">
              <li class="navbar__item navbar__item--dropdown">
                <LanguageToggle />
                <div
                  class="navbar__dropdown-menu navbar__dropdown-menu--languages"
                >
                  <LanguageDropdown :callback="changeLanguage" />
                </div>
              </li>
              <li
                v-if="isShoppingEnabled || auth2Store.isLoggedIn"
                class="navbar__item"
              >
                <a
                  href="#"
                  class="navbar__link navbar__link--active navbar__link__right"
                  @click="toggleSearch"
                >
                  <img
                    v-if="isDarkColor"
                    src="../assets/menu/search-white.svg"
                    alt="search"
                  />
                  <img
                    v-else
                    src="../assets/menu/search-blue.svg"
                    alt="search"
                  />
                </a>
              </li>
              <li class="navbar__item">
                <router-link
                  v-if="isShoppingEnabled"
                  class="navbar__link navbar__link__right navbar__link--active navbar__link--icon"
                  :to="{ name: 'cart' }"
                >
                  <div class="navbar__cart">
                    <span v-if="!cartStore.isEmpty">
                      {{ cartStore.count }}
                    </span>
                    <img
                      v-if="isDarkColor"
                      src="../assets/menu/cart-white.svg"
                      alt="cart"
                    />
                    <img v-else src="../assets/menu/cart-blue.svg" alt="cart" />
                  </div>
                </router-link>
              </li>
              <li v-if="!auth2Store.isLoggedIn" class="navbar__item">
                <router-link
                  :to="{ name: 'login' }"
                  class="navbar__link navbar__link--active navbar__link__right"
                >
                  <img src="../assets/avatar.svg" alt="login" />
                </router-link>
              </li>

              <li
                v-if="auth2Store.isLoggedIn"
                class="navbar__item navbar__item--dropdown"
              >
                <a
                  class="navbar__link navbar__link--active navbar__link__right dropdown-toggle navbar__link--round"
                  href="#"
                  @click.prevent
                >
                  <Avatar
                    :image="settingsStore.values.image"
                    :account="accountStore.values"
                  />
                </a>
                <div class="navbar__dropdown-menu">
                  <div class="navbar__profile-wrap">
                    <div class="navbar__profile-block">
                      <div class="navbar__profile-block-name">
                        {{ accountStore.values.firstname }}
                        {{ accountStore.values.lastname }}
                      </div>
                      <div
                        v-if="settingsStore.values.username"
                        class="navbar__profile-block-username"
                      >
                        {{ settingsStore.values.username }}
                      </div>
                    </div>
                    <div v-if="isShoppingEnabled" class="navbar__profile-block">
                      <router-link :to="{ name: 'dashboard' }">
                        {{ $t("common.dashboard") }}
                      </router-link>
                    </div>
                    <div v-if="isShoppingEnabled" class="navbar__profile-block">
                      <router-link :to="{ name: ROUTE.Dashboard.Courses }">
                        {{ $t("common.my_courses") }}
                      </router-link>
                    </div>
                    <div v-if="isShoppingEnabled" class="navbar__profile-block">
                      <router-link :to="{ name: 'dashboard.purchases' }">
                        {{ $t("common.purchases_history") }}
                      </router-link>
                    </div>
                    <div class="navbar__profile-block">
                      <router-link :to="{ name: 'dashboard.settings' }">
                        {{ $t("common.settings") }}
                      </router-link>
                    </div>
                    <OldApp />
                    <div class="navbar__profile-block">
                      <div
                        class="navbar__profile-block--logout"
                        @click="auth2Store.logout"
                      >
                        <img src="../assets/menu/logout-red.svg" alt="logout" />
                        <span>{{ $t("common.logout") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>

      <div v-show="megaMenuEnable" class="megamenu" tabindex="0">
        <div class="megamenu__content">
          <div class="container">
            <div class="megamenu__wrap">
              <div class="megamenu__block">
                <h3>{{ $t("megamenu.private.title") }}</h3>

                <ul class="megamenu__filters">
                  <li>
                    <router-link :to="{ name: ROUTE.Discover }">
                      {{ $t("megamenu.private.links.0.name") }}
                    </router-link>
                  </li>
                </ul>
              </div>

              <div class="megamenu__block">
                <h3>{{ $t("megamenu.business.title") }}</h3>

                <ul>
                  <li>
                    <router-link :to="{ name: 'business-and-institutions' }">
                      {{ $t("megamenu.business.links.0.name") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'about-business' }">
                      {{ $t("megamenu.business.links.1.name") }}
                    </router-link>
                  </li>
                </ul>
              </div>

              <div v-if="discountStore.discountBanner" class="megamenu__block">
                <div class="megamenu__banner">
                  <div class="megamenu__banner__left-section">
                    <img
                      :src="discountStore.discountBanner.image"
                      alt="Discount"
                    />
                  </div>
                  <div
                    class="megamenu__banner__right-section"
                    :style="{
                      backgroundColor: discountStore.discountBanner.color,
                    }"
                  >
                    <h1 v-text="discountStore.discountBanner.discount + '%'" />
                    <p v-text="discountStore.discountBanner.title" />
                    <div class="megamenu__banner__right-section__divider" />
                    <p
                      class="megamenu__banner__right-section__description"
                      v-text="discountStore.discountBanner.description"
                    />
                    <a
                      :href="discountStore.discountBanner.link"
                      :style="{
                        backgroundColor: discountStore.discountBanner.color,
                      }"
                    >
                      {{ discountStore.discountBanner.link_text }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="navbar-mobile">
    <div class="container">
      <div class="navbar-mobile__wrapper">
        <Search
          v-if="isSearchEnabled"
          ref="search"
          :search-results="searchResults"
          :search-value="route.query.q"
          @disable:search="disableSearch"
          @search="search"
          @submit:search="submitSearch"
          @show:suggestions="showSuggestions"
          @open:course="openCourse"
        />
        <template v-else>
          <div class="navbar-mobile__wrap">
            <a href="#" @click.prevent="popupStore.openMenu()">
              <svg
                v-if="!open"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="14px"
                viewBox="0 0 24 14"
                version="1.1"
              >
                <title>EECCA10A-AF6E-4E74-9E42-EA54B9DB9D10</title>
                <g
                  id="desktop"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="homepage-mobile"
                    transform="translate(-20.000000, -134.000000)"
                    stroke="#1B214A"
                    stroke-width="2"
                  >
                    <g id="menu" transform="translate(0.000000, 107.000000)">
                      <path
                        id="Combined-Shape"
                        d="M20,28 L44,28 M20,34 L44,34 M20,40 L44,40"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="33.9411255px"
                height="33.9411255px"
                viewBox="0 0 33.9411255 33.9411255"
                version="1.1"
              >
                <title>24184269-9C75-49FD-9BC6-B549D1C06594</title>
                <g
                  id="desktop"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="sidemenu"
                    transform="translate(-15.029437, -124.029437)"
                    stroke="#1B214A"
                    stroke-width="2"
                  >
                    <g id="menu" transform="translate(0.000000, 107.000000)">
                      <path
                        id="Combined-Shape"
                        d="M20,34 L44,34 M32,22 L32,46"
                        transform="translate(32.000000, 34.000000) rotate(-315.000000) translate(-32.000000, -34.000000) "
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>

          <div class="navbar__brand">
            <router-link :to="{ name: ROUTE.Home }">
              <img src="../assets/menu/hl-logo.svg" />
            </router-link>

            <template v-if="!isShoppingEnabled">
              <router-link
                :to="{ name: ROUTE.Home }"
                :title="$t('common.home')"
              >
                <img src="../assets/menu/lsmu-logo.svg" />
              </router-link>
            </template>
          </div>

          <div class="navbar-mobile__wrap">
            <a
              v-if="isShoppingEnabled || auth2Store.isLoggedIn"
              href="#"
              class="navbar__link navbar__link--icon"
              @click="toggleSearch"
            >
              <img src="../assets/menu/search-blue.svg" alt="search" />
            </a>
            <router-link
              v-if="isShoppingEnabled"
              class="navbar__link navbar__link--icon"
              :to="{ name: 'cart' }"
            >
              <div class="navbar__cart">
                <span v-if="!cartStore.isEmpty">
                  {{ cartStore.count }}
                </span>
                <img src="../assets/menu/cart-blue.svg" alt="cart" />
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>

  <MenuMobile v-if="popupStore.menu" />

  <div v-if="open" class="navbar-mobile-container">
    <div class="container">
      <ul class="navbar--mobile">
        <li class="navbar__item navbar__item--dropdown">
          <router-link class="navbar__link" :to="{ name: ROUTE.Discover }">
            {{ $t("megamenu.private.title") }}
          </router-link>
        </li>
        <li class="navbar__item navbar__item--dropdown">
          <router-link
            class="navbar__link"
            :to="{ name: 'business-and-institutions' }"
          >
            {{ $t("megamenu.business.title") }}
          </router-link>
        </li>
        <li class="navbar__item">
          <router-link class="navbar__link" :to="{ name: 'about' }">
            {{ $t("navigation.about") }}
          </router-link>
        </li>
        <li class="navbar__item">
          <router-link class="navbar__link" :to="{ name: 'howitworks' }">
            {{ $t("navigation.how-it-works") }}
          </router-link>
        </li>
        <li class="navbar__item">
          <router-link
            class="navbar__link"
            :to="{ name: 'page', params: { id: aboutPage } }"
          >
            {{ $t("footer.hybridlab.about") }}
          </router-link>
        </li>
        <li class="navbar__item">
          <router-link :to="{ name: 'contacts' }" class="navbar__link">
            {{ $t("navigation.help") }}
          </router-link>
        </li>
        <DropdownItemMobile
          :items="[
            {
              to: { name: 'projects.in-progress' },
              label: $t('navigation.projects.in_progress'),
            },
            {
              to: { name: 'projects.done' },
              label: $t('navigation.projects.done'),
            },
          ]"
        >
          {{ $t("navigation.projects.dropdown") }}
        </DropdownItemMobile>
        <template v-if="!isShoppingEnabled">
          <li v-if="auth2Store.isLoggedIn" class="navbar__item">
            <router-link
              :to="{ name: ROUTE.Dashboard.Courses }"
              class="navbar__link"
            >
              {{ $t("navigation.courses") }}
            </router-link>
          </li>
        </template>
        <template v-else>
          <DropdownItemMobile
            v-if="auth2Store.isLoggedIn"
            :items="[
              {
                to: { name: ROUTE.Dashboard.Courses },
                label: $t('common.my_courses'),
              },
              {
                to: { name: 'discover' },
                label: $t('common.all_courses'),
              },
            ]"
          >
            {{ $t("navigation.courses") }}
          </DropdownItemMobile>
          <li v-else class="navbar__item">
            <router-link :to="{ name: ROUTE.Discover }" class="navbar__link">
              {{ $t("navigation.courses") }}
            </router-link>
          </li>
        </template>
      </ul>

      <ul class="navbar--languages">
        <LanguageDropdown :callback="changeLanguage" />
      </ul>
    </div>
  </div>
</template>

<script>
import { useCartStore, useGlobalStore } from "@/store";
import { Field, Form } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import NavigationToggle from "./NavigationToggle.vue";
import { useSettingsStore } from "@/store/modules/settings.store";
import { useAccountStore } from "@/store/modules/account.store";
import DropdownItem from "@/components/navbar/DropdownItem.vue";
import OldApp from "./navbar/OldApp.vue";
import DropdownItemMobile from "./navbar/DropdownItemMobile.vue";
import { ROUTE } from "@/router/routenames";
import { getCurrentLanguageFilter } from "@/util/filters";
import LanguageDropdown from "@/components/navbar/LanguageDropdown.vue";
import LanguageToggle from "@/components/navbar/LanguageToggle.vue";
import { useAuth2Store } from "@/store/modules/auth2.store";
import MenuMobile from "./navigation/MenuMobile.vue";
import { usePopupStore } from "@/store/modules/popup.store";
import { computed, onMounted, ref } from "vue";
import { useDiscountStore } from "@/store/modules/discount.store";
import { isShoppingEnabled } from "@/util/helpers";
import { COLOR_DARK, useHeaderStore } from "@/store/modules/header.store";
import { storeToRefs } from "pinia";
import SearchForm from "@/components/search/SearchForm.vue";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import { useProductsStore } from "@/store/modules/products.store";
import Search from "@/components/Search.vue";
import { onClickOutside } from "@vueuse/core";
import Avatar from "@/components/Avatar.vue";

export default {
  directives: {},
  components: {
    Avatar,
    Search,
    SearchForm,
    LanguageToggle,
    LanguageDropdown,
    Form,
    Field,
    NavigationToggle,
    DropdownItem,
    OldApp,
    DropdownItemMobile,
    MenuMobile,
  },
  props: {
    isStatic: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const auth2Store = useAuth2Store();
    const cartStore = useCartStore();
    const globalStore = useGlobalStore();
    const settingsStore = useSettingsStore();
    const accountStore = useAccountStore();
    const popupStore = usePopupStore();
    const discountStore = useDiscountStore();
    const headerStore = useHeaderStore();
    const purchasedStore = getPurchasedStore();
    const productsStore = useProductsStore();

    const router = useRouter();
    const route = useRoute();
    const { lightOrDark } = storeToRefs(headerStore);

    const isSticky = ref(false);
    const isSearchEnabled = ref(false);
    const navbar = ref(null);
    const searchResults = ref([]);
    const search = ref(null);

    onMounted(() => {
      onClickOutside(search, (event) => (isSearchEnabled.value = false));
    });

    const isDarkColor = computed(
      () =>
        lightOrDark.value === COLOR_DARK && !isSticky.value && !props.isStatic,
    );

    accountStore.request();
    discountStore.request();

    const aboutPage = process.env.VUE_APP_ABOUT_PAGE;

    return {
      auth2Store,
      cartStore,
      globalStore,
      settingsStore,
      accountStore,
      popupStore,
      discountStore,
      router,
      route,
      aboutPage,
      ROUTE,
      isSticky,
      isSearchEnabled,
      navbar,
      COLOR_DARK,
      isDarkColor,
      purchasedStore,
      productsStore,
      searchResults,
      search,
    };
  },
  data() {
    return {
      megaMenuEnable: false,
      current_filter: 0,
      langs: ["lt", "en", "ru"],
      open: false,
    };
  },
  computed: {
    isShoppingEnabled() {
      return isShoppingEnabled();
    },
    showTransition() {
      return !this.megaMenuEnable;
    },
  },
  async created() {
    await this.cartStore.getItems();

    let vm = this;

    window.addEventListener(
      "resize",
      function () {
        vm.open = false;
        vm.megaMenuEnable = false;
      },
      true,
    );

    window.addEventListener("scroll", this.handleScroll);

    if (window.innerWidth <= 1253) {
      this.open = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleMegaMenu() {
      this.megaMenuEnable = !this.megaMenuEnable;
    },
    toggleSearch(event) {
      event.preventDefault();
      this.isSearchEnabled = !this.isSearchEnabled;
    },
    clickOutside() {
      this.megaMenuEnable = false;
    },
    onFilter(filter, group) {
      this.globalStore.updateFilters(filter);
      this.megaMenuEnable = false;

      this.router.push({
        name: ROUTE.Discover,
        query: {
          filter_group_id: group.filter_group_id,
          filter_id: filter.filter_id,
        },
      });
    },
    search(values) {
      if (this.isShoppingEnabled) {
        if (this.route.name == ROUTE.Discover) {
          this.$router.replace({
            name: ROUTE.Discover,
            query: { q: values.q },
          });
        } else {
          this.router.push({ name: ROUTE.Discover, query: { q: values.q } });
        }
      } else {
        if (this.route.name == ROUTE.Dashboard.Courses) {
          this.$router.replace({
            name: ROUTE.Dashboard.Courses,
            query: { q: values.q },
          });
        } else {
          this.router.push({
            name: ROUTE.Dashboard.Courses,
            query: { q: values.q },
          });
        }
      }
    },
    async showSuggestions(value) {
      if (this.isShoppingEnabled) {
        await this.productsStore.requestProductsBySearch(value);
        this.searchResults = this.productsStore.productsBySearch;
      } else {
        await this.purchasedStore.getCourses(value);
        this.searchResults = this.purchasedStore.courses;
      }
    },
    submitSearch(value) {
      this.router.push({
        name: this.isShoppingEnabled ? ROUTE.Discover : ROUTE.Dashboard.Courses,
        query: { q: value },
      });
    },
    disableSearch() {
      this.isSearchEnabled = false;
      this.searchResults = [];
    },
    selectFilterCategory(id) {
      this.current_filter = id;
    },
    toggleMenu() {
      this.megaMenuEnable = false;
      this.open = !this.open;
    },
    getCurrentLanguageImage() {
      return require("@/assets/menu/" + this.$root.$i18n.locale + ".png");
    },
    getLanguageImage(lang) {
      const language = lang.code.split("-");
      return require("@/assets/menu/" + language[0] + ".png");
    },
    getCurrentLanguage() {
      return this.$root.$i18n.locale;
    },
    async openCourse(id) {
      await this.router.push({
        name: this.isShoppingEnabled
          ? ROUTE.Course.About
          : ROUTE.Course.General,
        params: { id: id },
      });
    },
    changeLanguage() {
      if (ROUTE.Discover === this.$router.currentRoute.value.name) {
        const { filterGroup, filter } = getCurrentLanguageFilter();

        if (filter && filterGroup) {
          this.globalStore.resetLanguageFilters(
            this.$root.$i18n.availableLocales,
          );
          this.onFilter(filter, filterGroup);
        } else if (!filter) {
          this.globalStore.resetLanguageFilters(
            this.$root.$i18n.availableLocales,
          );
        }
      }
    },
    handleScroll() {
      this.isSticky = window.pageYOffset >= this.navbar.offsetTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-hidden {
  background-color: rgba(27, 33, 74, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.navbar-mobile-container {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - $header-height);
  background: white;
  z-index: 99;
}

.navbar-mobile {
  position: sticky;
  background: white;
  width: 100%;
  top: 0;
  display: none;
  height: $header-height;
  z-index: 99;

  @media screen and (max-width: 1253px) {
    display: flex;
    align-items: center;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 10px;

    a {
      svg {
        width: 30px;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.megamenu {
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: 20;

  &__filter-item {
  }

  &__filters {
    &--count {
      opacity: 0.5;
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
    }

    &--active {
      a {
        font-weight: 500;
      }

      ul {
        display: block !important;

        a {
          font-weight: 500;
        }
      }
    }

    li {
      ul {
        display: none;

        li {
          padding-left: 20px !important;
        }

        a {
          color: $color-primary;
          font-family: $font-family;
          font-size: $font-size-sm;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
  }

  &__content {
    background-color: $color-white;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
  }

  &__block {
    width: 285px;
    margin-bottom: 20px;

    h3 {
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      line-height: 15px;
      text-align: left;
      color: $color-primary;
      letter-spacing: 0;
      margin-bottom: 19px;
      margin-top: 16px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li {
        a {
          font-family: $font-family;
          font-size: $font-size-md;
          letter-spacing: 0;
          line-height: 28px;
          text-decoration: none;
          color: $color-primary;
        }
      }
    }
  }

  &__banner {
    display: flex;
    width: 100%;
    min-width: 525px;
    max-height: 360px;
    margin: 10px auto 15px;

    &__left-section {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__right-section {
      background-color: var(--banner-background-color, $color-primary);
      color: $color-white;
      padding: 0 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 60%;

      h1 {
        font-family: $font-family;
        font-size: 42px;
        font-weight: 700;
        line-height: 15px;
        text-align: center;
        margin-bottom: 16px;
        margin-top: 60px;
      }

      p {
        font-family: $font-family;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
      }

      &__divider {
        width: 30px;
        height: 4px;
        margin: 22px 0;
        background-color: $color-white;
        border-radius: 2px;
      }

      &__description {
        margin-bottom: 45px;
      }

      a {
        font-family: $font-family;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        background-color: var(--banner-background-color, $color-primary);
        border-radius: 8px;
        color: $color-white;
        border: 2px solid white;
        padding: 10px 20px;
        min-width: 200px;
        margin-top: auto;
        margin-bottom: 82px;
      }
    }
  }
}

.navbar {
  transition: background-color 0.75s ease-in-out;
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 11;

  &--static {
    position: static;
  }

  &__brand {
    display: inline-flex;
    gap: 16px;

    img {
      height: 52px;
    }
  }

  &--languages {
    padding-left: 0;

    a {
      padding: 16px 0 16px;

      img {
        height: 26px;
        width: 26px;
      }
    }
  }

  &--mobile {
    padding-left: 0;

    .navbar__item--dropdown {
      &:hover {
        @media screen and (max-width: 900px) {
          display: block;
        }
      }
    }

    .navbar__dropdown-menu {
      position: relative;
      width: 100%;
      background: transparent;
      box-shadow: none;
      top: inherit;

      a {
        box-shadow: none;
        text-decoration: none;
      }

      li {
        margin-bottom: 5px;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        a {
          color: $color-primary;
          font-family: $font-family;
          font-size: $font-size-sm;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }

    a {
      padding: 16px 0 16px;
      box-shadow: inset 0 -1px 0 0 $color-light-grey;
      width: 100%;
    }
  }

  @media screen and (max-width: 1253px) {
    display: none;
  }

  &__collapse {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  @keyframes fill-white {
    from {
      background-color: transparent;
    }
    to {
      background-color: white;
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5;
    background-color: white;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 68px;
  }

  &__profile-wrap {
  }

  &__profile-block-name {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-slg;
    letter-spacing: 0;
    line-height: 15px;
    font-weight: 500;
  }

  &__profile-block-username {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 15px;
    font-weight: 400;
    margin-top: 8px;
  }

  &__profile-block--logout {
    color: $color-light-coral;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__profile-block-profile {
    opacity: 0.6;
    color: $color-primary;
    font-family: $font-family;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }

  &__profile-block {
    &:first-child {
      padding: 26px 20px;
    }

    &:last-child {
      padding: 16px 20px;
    }

    a {
      display: block;
      padding: 16px 20px;

      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-md;
      letter-spacing: 0;
      line-height: 15px;

      text-decoration: none;

      &.navbar__item--active {
        font-weight: 400 !important;
        color: $color-primary;
      }
    }

    box-shadow: inset 0 -1px 0 0 $color-light-grey;
  }

  &__megamenu-toggler {
    &--active {
      a {
        &:after {
          // background: url('../assets/menu/arrow_up.svg');
        }
      }
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      text-align: right;
      text-decoration: none;
      position: relative;
      text-transform: uppercase;

      display: flex;
      align-items: center;

      padding-right: 20px;

      &:after {
        background: url("../assets/menu/arrow-down-blue.svg");
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        right: 0px;
      }
    }
  }

  &__item {
    &--active {
      font-weight: bold;
    }
  }

  &__controls {
  }

  &__search-wrap {
    position: relative;
    display: flex;
    align-items: center;

    button {
      position: absolute;
      right: 4px;
      height: 40px;
      width: 40px;
      background-color: transparent;
      border: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    input {
      border: 1px solid $color-light-grey;
      border-radius: 24px;
      width: 100%;
      padding: 14px 30px;
    }
  }

  &__navbar-collapse {
    display: flex;
    align-items: center;
    gap: 20px;

    ul {
      padding-left: 0px;
      list-style-type: none;
      display: flex;
    }
  }

  &__nav {
  }

  &__item {
    display: flex;

    &--dropdown {
      position: relative;
      z-index: 9;
      min-height: 42px;

      @media screen and (max-width: 1253px) {
        font-weight: bold;
      }

      &:hover {
        .navbar__dropdown-menu {
          display: flex;
        }
      }
    }
  }

  &__dropdown-menu {
    display: none;
    background: #ffffff;
    border-radius: 4px;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: -15px;
    width: 260px;
    box-shadow: 0 3px 15px 0 rgba(27, 33, 74, 0.15);

    &--languages {
      width: auto;
      padding: 10px;

      a {
        padding: 3px;
      }

      img {
        height: 26px;
        width: 26px;
      }
    }
  }

  &__dropdown-item {
    padding: 20px;

    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;

    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
    }

    &:hover {
      background: rgba(9, 31, 44, 0.04);
    }
  }

  &__link,
  :deep() .navbar__link {
    padding: 5px 20px;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-xs;
    text-transform: uppercase;
    line-height: 15px;
    font-weight: 500;

    display: flex;
    align-items: center;
    text-decoration: none;

    @media screen and (max-width: $breakpoint-lg) {
      opacity: 1;
    }

    &__right {
      padding: 5px 10px;
    }

    &--btn {
      height: 48px;
      width: 112px;
      border: 1px solid $color-light-grey;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--round {
      img {
        border-radius: 30px;
      }
    }

    &--icon {
      @media screen and (max-width: 900px) {
        padding: 5px 0px;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  &__cart {
    display: flex;

    span {
      position: absolute;
      top: -10px;
      right: 0px;

      color: #ffffff;
      font-family: $font-family;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;

      height: 18px;
      width: 18px;
      background-color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 20px;
    }
  }

  &--dark {
    background-color: transparent;

    .navbar__link,
    :deep() .navbar__link {
      color: $color-white;
      opacity: 0.6;

      &:hover,
      &--active {
        opacity: 1;
      }

      &:not(.navbar__link--round).dropdown-toggle:after {
        background: url("../assets/menu/arrow-down-white.svg");
      }
    }
  }

  &--disabled-transition {
    transition: none;
  }
}
</style>
